exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-center-js": () => import("./../../../src/pages/center.js" /* webpackChunkName: "component---src-pages-center-js" */),
  "component---src-pages-cms-404-js": () => import("./../../../src/pages/cms/404.js" /* webpackChunkName: "component---src-pages-cms-404-js" */),
  "component---src-pages-cms-contents-js": () => import("./../../../src/pages/cms/contents.js" /* webpackChunkName: "component---src-pages-cms-contents-js" */),
  "component---src-pages-cms-create-assistant-js": () => import("./../../../src/pages/cms/create/assistant.js" /* webpackChunkName: "component---src-pages-cms-create-assistant-js" */),
  "component---src-pages-cms-create-doctor-js": () => import("./../../../src/pages/cms/create/doctor.js" /* webpackChunkName: "component---src-pages-cms-create-doctor-js" */),
  "component---src-pages-cms-events-js": () => import("./../../../src/pages/cms/events.js" /* webpackChunkName: "component---src-pages-cms-events-js" */),
  "component---src-pages-cms-index-js": () => import("./../../../src/pages/cms/index.js" /* webpackChunkName: "component---src-pages-cms-index-js" */),
  "component---src-pages-cms-photos-js": () => import("./../../../src/pages/cms/photos.js" /* webpackChunkName: "component---src-pages-cms-photos-js" */),
  "component---src-pages-cms-photos-new-js": () => import("./../../../src/pages/cms/photos/new.js" /* webpackChunkName: "component---src-pages-cms-photos-new-js" */),
  "component---src-pages-cms-services-js": () => import("./../../../src/pages/cms/services.js" /* webpackChunkName: "component---src-pages-cms-services-js" */),
  "component---src-pages-cms-tabs-js": () => import("./../../../src/pages/cms/tabs.js" /* webpackChunkName: "component---src-pages-cms-tabs-js" */),
  "component---src-pages-cms-team-js": () => import("./../../../src/pages/cms/team.js" /* webpackChunkName: "component---src-pages-cms-team-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-philosophy-js": () => import("./../../../src/pages/philosophy.js" /* webpackChunkName: "component---src-pages-philosophy-js" */),
  "component---src-pages-photos-js": () => import("./../../../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

